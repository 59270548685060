import { type } from "@testing-library/user-event/dist/type";
import "../css/Navbar.css";
import { useRef, useEffect, useState } from "react";

export default function Header() {
  const [isClicked, setisClicked] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const hasScroll = useRef(false);
  const hasAnimFinish = useRef(true);
  const done = useRef(true);
  function typewriterEffectNav(selector, speed, delay) {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    let div;
    try {
      div = document.querySelector(selector);
    } catch {
      div = selector;
    }
    const phrase = JSON.parse(div.getAttribute("data-value") || "[]");

    let sleepTime = speed;
    let delayTime = delay;
    let curIndex = 0;

    const writeLoop2 = async () => {
      try {
        while (true) {
          await sleep(delayTime);
          let currWord = phrase[curIndex];
          //tulisin array pertama
          for (let i = 0; i < currWord.length; i++) {
            if (done.current) {
              div.textContent = currWord.substring(0, i + 1);
              await sleep(sleepTime);
            }
          }
          return;
        }
      } catch (error) {
        console.error("An error occurred during the typewriter effect:", error);
      }
    };
    writeLoop2();
  }

  function deleteType(selector, speed, delay) {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    let div;
    try {
      div = document.querySelector(selector);
    } catch {
      div = selector;
    }
    const phrase = JSON.parse(div.getAttribute("data-value") || "[]");

    let sleepTime = speed;
    let delayTime = delay;
    let curIndex = 0;
    const deleteLoop = async () => {
      while (true) {
        let currWord = phrase[curIndex];

        for (let i = currWord.length; i > 0; i--) {
          div.textContent = currWord.substring(0, i - 1);
          await sleep(sleepTime / 2);
        }
        done.current = true;
        return;
      }
    };
    deleteLoop();
  }
  const handleClick = (e) => {
    setisClicked(!isClicked);
    const list = document.querySelectorAll(".lists");
    const content = document.querySelectorAll(".content");
    const ham = document.querySelector(".hamburg");
    const text = document.querySelector(".text");
    const component = document.querySelector("#component");
    if (!isClicked) {
      component.classList.remove("pointer-events-none");
      ham.classList.add("hamburger");
      ham.classList.add("bg-main");
      ham.classList.remove("bg-accent");
      hasAnimFinish.current = false;
      component.classList.remove("opacity-0");
      component.classList.remove("-translate-y-1/2");
      content.forEach((el) => {
        typewriterEffectNav(el, 100, 1000);
      });
      list.forEach((item) => {
        item.classList.remove("bg-main");
        item.classList.add("bg-accent");
      })
    } else {
      // component.classList.remove("animate-active");
      ham.classList.remove("hamburger");
      ham.classList.remove("bg-main");
      hasAnimFinish.current = true;
      done.current = false;
      list.forEach((item) => {
        item.classList.add("bg-main");
        item.classList.remove("bg-accent");
      })

      setTimeout(() => {
        if (hasAnimFinish.current) {
          component.classList.add("-translate-y-1/2");
          component.classList.add("opacity-0");
        }
      }, 1000);
      ham.classList.add("bg-accent");
      component.classList.add("pointer-events-none");

      content.forEach((el) => {
        deleteType(el, 100, 500);
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector(".nav");
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop && hasScroll.current == false) {
        hasScroll.current = true;
        nav.classList.remove("translate-y-0");
        nav.classList.add("translate-y-[-100%]");
      } else if (
        currentScrollTop < lastScrollTop &&
        hasScroll.current == true
      ) {
        hasScroll.current = false;
        nav.classList.remove("translate-y-[-100%]");
        nav.classList.add("translate-y-0");
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);
  return (
    <div
      id="header"
      className="fixed z-[99] transition duration-700 w-screen nav translate-y-0"
    >
      <div className="flex items-center overflow-hidden md:px-10 md:p-5 p-3 px-2">
        <div className="md:text-2xl text-base font-poppins w-[50%] z-[99] text transition duration-300 text-main">
          <p>Alfonsus Vega H.T.</p>
        </div>
        <div className="flex items-center justify-end w-[50%] h-full">
          <input
            type="checkbox"
            name="hamburger"
            id="hamburger"
            className="peer"
            hidden
          />
          <label
            for="hamburger"
            className="hamburg flex justify-center items-center cursor-pointer z-[10] bg-accent transition duration-700 rounded-full w-[3rem] md:w-[4rem] h-[3rem] md:h-[4rem]"
            onClick={(e) => handleClick()}
          >
            <div>
              <div className="lists m-auto relative h-0.5 w-5 md:w-7 rounded-xl bg-main transition duration-700 z-[100]"></div>
              <div className="lists m-auto relative md:mt-[0.3rem] mt-[0.25rem] h-0.5 w-5 md:w-7 rounded-xl bg-main transition duration-500 z-[100]"></div>
              <div className="lists m-auto relative md:mt-[0.3rem] mt-[0.25rem] h-0.5 w-5 md:w-7 rounded-xl bg-main transition duration-500 z-[100]"></div>
            </div>
          </label>

          <div
            id="component"
            className="components opacity-0 fixed inset-0 w-[100%] transition duration-500 p-0 m-0 md:text-6xl text-3xl pointer-events-none"
          >
            <div className="flex relative bg-accent w-full h-max md:py-28 py-20 px-[5%] text-main font-poppins">
              <div className="w-full border-2 h-screen border-main p-10 rounded-3xl">
                <li className="">
                  <button
                    onClick={(e) => handleClick()}
                    className="md:px-5 px-2"
                  >
                    <a
                      href="#"
                      data-value='["Home"]'
                      className="content cursor"
                    ></a>
                  </button>
                </li>
                <li className="mt-6">
                  <button
                    onClick={(e) => handleClick()}
                    className="md:px-5 px-2"
                  >
                    <a
                      href="#work"
                      data-value='["My Works"]'
                      className="content cursor"
                    ></a>
                  </button>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
